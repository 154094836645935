<template>
  <div class="con">
      <h1>404</h1>
  </div>
</template>

<script>
export default {
  name: "template",
  components: {},
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";

</style>
